/* Resets */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* App ----------*/
:root {
  --main-bg-color: #000000;
  --main-fg-color: rgb(255, 255, 255);
  --accent-color: #292929;
  --dark-text-color: #cfcfcf;
}
body {
  background-color: var(--main-bg-color);
}

#root,
.app,
body,
html {
  height: 100%;
  max-height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

body,
.search,
.search::placeholder {
  background-color: var(--main-bg-color);
  color: var(--main-fg-color);
}

a:link,
a:visited,
a:hover,
a:active {
  color: var(--main-fg-color);
}

.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 38px 1fr 40px;
  overflow: hidden;
}

.page {
  width: 100%;
  margin: 6px 10px 0 10px;
  overflow-y: auto;
  -ms-overflow-style: none;

  max-width: 500px;
  justify-self: center;
  /* text-align: center; */
}
.page::-webkit-scrollbar {
  display: none;
}
