.fixture-row,
.remove-button,
.remove-button-x,
.quantity-input {
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.fixture-row {
  /* Layout */
  display: grid;
  grid-template-columns: 60px 1fr 88px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  align-self: center;
  /* Style */
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1em;
  border-style: solid;
  border-color: var(--accent-color);
  border-width: 1px 0;
}

.side-img-container {
  height: 50px;
  width: 50px;
  overflow: hidden;
  position: relative;
  /* background-color: rgb(212, 255, 251); */
  border-radius: 5px;
}

.side-img {
  /* width: 50px; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}

.fixture-row-title {
  margin-bottom: 6px;
  font-size: 1.2em;
}

/* When on desktop, links underline on hover. */
@media (hover: hover) and (pointer: fine) {
  .fixture-row:hover {
    background-color: var(--accent-color);
  }

  .fixture-row-title:hover {
    text-decoration: underline;
  }
}

/* Make more buttony... */
.fixture-row-title,
.fixture-change-icon,
.remove-button-x {
  cursor: pointer;
}

.fixture-in-row-details {
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  color: var(--dark-text-color);
}

.remove-button,
.quantity-input input {
  background-color: transparent;
  color: var(--main-fg-color);
}

.remove-button {
  align-self: center;
  border-style: none;
  border-width: 0;
  border-radius: 50%;
  padding: 0;
  height: 20px;
  width: 20px;
  margin: 0;
  vertical-align: center;
  display: inline-block;
}

.remove-button-x {
  margin: 0;
  width: 100%;
  height: 100%;
}

.hidden {
  visibility: hidden;
}
