.centered {
    text-align: center;
    padding-top: 50px;
}

.centered > * {
    padding-top: 25px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;  
    padding: 10px 50px; 
}