/* Footer ----------*/
footer {
  /* Layout */
  height: 40px;
  display: flex;
  justify-content: center;
  /* Style */
  background-color: var(--accent-color);
}

.footer-button-left,
.footer-button-right {
  flex: 1 0 auto;
  max-width: 210px;
  text-align: center;
  padding: 4px 0;
}

.footer-button-left {
  margin: 0 15px 0 40px;
}

.footer-button-right {
  margin: 0 40px 0 15px;
}

.footer-button-icon {
  height: 32px;
  width: 32px;
  /* Clicking on this icon activates container (.footer-button-*) */
  pointer-events: none;
}
