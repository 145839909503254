/* Review/Summary page */
.selected-heading h2 {
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
}

.selected-heading {
  text-align: center;
}
