.fixture-change {
  /* height: 100%; */
  text-align: right;
  /* grid-area: changer; */
  vertical-align: middle;
  display: inline-block;
  align-self: center;
}
.fixture-change > * {
  display: inline-block;
  vertical-align: middle;
}

.fixture-change-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.8em;
  color: var(--dark-text-color);
}

.quantity-input input {
  width: 30px;
  height: 28px;
  padding: 0px;
  border-radius: 10px;
  border-style: none;
  border-width: 1px;
  outline-style: none;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5em;
}
.quantity-input input::placeholder {
  color: var(--main-bg-color);
  /* font-size: 2em; */
  margin: 0;
  padding: 0;
}
.quantity-input input:focus::placeholder {
  color: transparent;
}

.hidden {
  visibility: hidden;
}
