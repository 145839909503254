/* Fixture Table */
.fixture-table {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.search-fail-text {
  text-align: center;
  padding-top: 40%;
  color: var(--dark-text-color);
}
